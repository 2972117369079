/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ZTitle from '../system/ZTitle';
import { useSelector } from 'react-redux';
import ZLoader from '../system/ZLoader';
import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Mask, Isset } from '../../utils';
import ZPagination from '../system/ZPagination';
import ZFiltros from '../system/ZFiltros';
import axios from 'axios';
import config from 'react-global-configuration';
import format from 'date-fns/format';
import Tooltip from '@material-ui/core/Tooltip';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';
import { FaEye, FaCheckCircle, FaStopCircle, FaExclamationCircle, FaTrash, FaHourglassStart } from 'react-icons/fa';
import ZModalConfirm from '../system/ZModalConfirm';
import { addMessage } from '../../store/alert';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	menuIcon: {
		fontSize: 16,
		cursor: 'pointer',
		marginRight: 4,
	},
	empty: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: 18,
		fontWeight: 700,
		height: 100,
	},
}));

const getStatusIcon = statusId => {
	switch (+statusId) {
		case 2:
			return (
				<Tooltip title="Pago">
					<div>
						<FaCheckCircle style={{ color: green[500] }} />
					</div>
				</Tooltip>
			);
		case 3:
			return (
				<Tooltip title="Atrasado">
					<div>
						<FaStopCircle style={{ color: red[400] }} />
					</div>
				</Tooltip>
			);
		case 1:
		default:
			return (
				<Tooltip title="Pendente">
					<div>
						<FaExclamationCircle style={{ color: yellow[700] }} />
					</div>
				</Tooltip>
			);
		case 4:
			return (
				<Tooltip title="Gerando Carnê">
					<div>
						<FaHourglassStart />
					</div>
				</Tooltip>
			);
	}
};

export default props => {
	const location = useLocation();
	const history = useHistory();
	const dispatch = useDispatch();
	const canAccessCarnes = useSelector(state =>
		Isset(() => state.system.params['31'].valor) ? state.system.params['31'].valor : null
	);
	// const state = useSelector(state => state);
	// // console.log('state:', state);
	// const store = useSelector(store => store);
	// //console.log('store:', store);
	if (!canAccessCarnes) {
		history.push('/');
	}

	const token = useSelector(store => store.auth.token);
	const usuario = useSelector(store => store.auth.usuario);
	const estabelecimentoAdmin = useSelector(store => store.admin.estabelecimento);

	const classes = useStyles();

	const [loading, setLoading] = useState(true);
	const [carnes, setCarnes] = useState([]);
	const [removeCarne, setRemoveCarne] = useState({
		open: false,
		id: null,
		titulo: null,
	});

	// Paginação
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(15);
	const [totalRows, setTotalRows] = useState(0);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	// Filtros
	const [filtros, setFiltros] = useState({
		id: '',
		titulo: '',
		valor: '',
		cliente: '',
	});
	const filtrosChange = data => {
		setPage(1);
		setFiltros({
			...data,
		});
	};

	const getCarnes = async () => {
		const valor = filtros.valor ? Mask.OnlyNumber(filtros.valor) / 100 : null;
		setLoading(true);
		try {
			const res = await axios.get(`${config.get('apiUrl')}carnes`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
				params: {
					page,
					valor: valor,
					cliente: filtros.cliente,
					titulo: filtros.titulo,
					id: filtros.id,
					limit: rowsPerPage,
					estabelecimentoId: Isset(() => estabelecimentoAdmin.id)
						? estabelecimentoAdmin.id
						: usuario.principal_estabelecimento_id,
				},
			});

			if (res.data.success) {
				setCarnes(res.data.carnes);
				setTotalRows(res.data.totalResult);
			}
		} catch (error) {
			console.log(error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getCarnes();
	}, [estabelecimentoAdmin, page, rowsPerPage, filtros]);
	useEffect(() => {
		if (location.pathname === '/carnes') {
			const intervalId = setInterval(() => {
				getCarnes();
			}, 30000);
			return () => {
				clearInterval(intervalId);
			};
		}
	}, [page, rowsPerPage, filtros]);
	const handleRemoveCarne = async success => {
		if (success && removeCarne.id) {
			try {
				const res = await axios.delete(`${config.get('apiUrl')}carnes/${removeCarne.id}`, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (res.data.success) {
					getCarnes();
				} else {
					dispatch(addMessage({ type: 'error', message: 'Erro ao remover carnê.' }));
				}
			} catch (error) {
				console.log(error);
			}
		}
		setRemoveCarne({
			open: false,
			id: null,
			titulo: null,
		});
	};

	return (
		<Grid container>
			<ZTitle title="Carnês" button="add" handleOnClick={() => history.push(`/carnes/new`)} />
			<Grid item xs={12}>
				<Paper
					style={{
						position: 'relative',
						marginBottom: 10,
						borderRadius: 4,
						padding: '5px 10px',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<ZFiltros
						onChange={filtrosChange}
						input={[
							{
								key: 'titulo',
								placeholder: 'Titulo do Carnê',
								defaultValue: filtros.titulo,
								modal: false,
							},
							{
								key: 'cliente',
								placeholder: 'Nome do Cliente',
								defaultValue: filtros.cliente || '',
								modal: false,
							},
							{
								key: 'id',
								placeholder: 'Id do Carnê',
								defaultValue: filtros.id || '',
								modal: false,
							},
							{
								key: 'valor',
								title: 'Valor',
								defaultValue: filtros.valor,
								mask: 'real',
							},
						]}
					/>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				{loading ? (
					<ZLoader height="200px" size={40} />
				) : (
					<>
						{carnes.length > 0 ? (
							<>
								<Paper style={{ overflowX: 'auto', width: 'calc(100vw - 45px)' }}>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell>ID</TableCell>
												<TableCell>Titulo</TableCell>
												<TableCell>Cliente</TableCell>
												<TableCell align="right">Parcelas</TableCell>
												<TableCell align="right">Valor</TableCell>
												<TableCell align="right"></TableCell>
												<TableCell align="right">Dia de Vencimento</TableCell>
												<TableCell align="right">Criado Por</TableCell>
												<TableCell align="right">Data Criação</TableCell>
												<TableCell align="right"></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											<>
												{carnes.map((carne, key) => (
													<TableRow hover key={key}>
														<TableCell component="th" scope="row">
															{carne.id}
														</TableCell>
														<TableCell component="th" scope="row">
															{carne.titulo}
														</TableCell>
														<TableCell component="th" scope="row">
															{carne.cliente.nome}
														</TableCell>
														<TableCell align="right">{carne.parcelas}</TableCell>
														<TableCell align="right">{Mask.Real(carne.valor)}</TableCell>
														<TableCell align="right">
															<div style={{ marginRight: 10, fontSize: 15 }}>
																{getStatusIcon(carne.status_carne_id)}
															</div>
														</TableCell>
														<TableCell align="right">{carne.dia_vencimento}</TableCell>
														<TableCell align="right">{carne.usuario.nome}</TableCell>
														<TableCell align="right">
															{format(carne.created, 'DD/MM/YYYY HH:mm')}
														</TableCell>
														<TableCell align="right">
															{carne.status_carne_id !== 4 && (
																<Tooltip title="Visualizar">
																	<span>
																		<FaEye
																			className={classes.menuIcon}
																			onClick={() => {
																				history.push(`/carnes/${carne.id}`);
																			}}
																		/>
																	</span>
																</Tooltip>
															)}
															<Tooltip title="Remover">
																<span>
																	<FaTrash
																		className={classes.menuIcon}
																		onClick={() => {
																			setRemoveCarne({
																				open: true,
																				id: carne.id,
																				titulo: carne.titulo,
																			});
																		}}
																	/>
																</span>
															</Tooltip>
														</TableCell>
													</TableRow>
												))}
											</>
										</TableBody>
										<ZPagination
											count={totalRows}
											rowsPerPage={rowsPerPage}
											page={page - 1}
											onChangePage={onChangePage}
											onChangeRowsPerPage={onChangeRowsPerPage}
										/>
									</Table>
								</Paper>
							</>
						) : (
							<div className={classes.empty}>Nenhum Carnê Encontrado</div>
						)}
					</>
				)}
			</Grid>
			<ZModalConfirm
				title={`Remover Carnê`}
				message={`Deseja remover esse Carnê (${removeCarne.titulo || ''})?`}
				open={removeCarne.open}
				onClose={handleRemoveCarne}
			/>
		</Grid>
	);
};
