import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Grid,
	TextField,
	FormControl,
	MenuItem,
	InputLabel,
	Select,
	Typography,
	Button,
	makeStyles,
} from '@material-ui/core';
import Cards from 'react-credit-cards';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import LinkPagamento from '../VendaDigitada/CadastrarVendaDigitada';

import { Mask, Formatter, Isset, isWhite } from '../../utils';
import ZDatePicker from '../system/ZDatePicker';
import Axios from 'axios';
import config from 'react-global-configuration';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(theme => ({
	fullWidth: {
		width: '100%',
	},
	formaPagamento: {
		padding: 20,
		fontSize: 16,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		margin: 'auto',
		marginBottom: 32,
		fontWeight: 700,
		color: '#333',
		maxWidth: 263,
		border: `solid 1px rgb(234, 234, 234)`,
		borderRadius: 8,
		cursor: 'pointer',
		'&.selected': {
			border: `solid 1px ${theme.palette.primary.main}`,
			color: theme.palette.primary.main,
		},
		'&:hover': {
			border: `solid 1px `,
			borderColor: isWhite(theme.palette.primary.main) ? '#808080 ' : theme.palette.primary.main,
			color: isWhite(theme.palette.primary.main) ? '#808080 ' : theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			padding: 10,
			marginBottom: 20,
		},
	},
	stepActions: {
		marginTop: 60,
		textAlign: 'center',
	},
	backButton: {
		color: isWhite(theme.palette.primary.main) ? '#B4B4B4' : theme.palette.primary.main,
		border: `solid 1px `,
		borderColor: isWhite(theme.palette.primary.main),
		width: 171,
		height: 44,
		marginRight: 10,
		'&:hover': {
			color: isWhite(theme.palette.primary.main)
				? '#313131'
				: theme.palette.primary.main
				? '#fff'
				: theme.palette.getContrastText(theme.palette.primary.main),
			backgroundColor: theme.palette.primary.main,
		},
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
			width: '100%',
		},
	},
	nextButton: {
		width: 171,
		height: 44,
		backgroundColor: isWhite(theme.palette.primary.main) ? '#EEEEEE' : theme.palette.primary.main,
		color: isWhite(theme.palette.primary.main)
			? '#606060'
			: theme.palette.getContrastText(theme.palette.primary.main),
		'&:hover': {
			backgroundColor: isWhite(theme.palette.primary.main) ? '#ccc' : theme.palette.primary.dark,
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	stepTitle: {
		color: '#3E3B47',
		marginBottom: 48,
	},
}));

function Title({ title }) {
	const classes = useStyles();

	return (
		<Typography variant="h5" component="h6" className={classes.stepTitle}>
			{title}
		</Typography>
	);
}

function Buttons({ changeStep, setActiveStep, data }) {
	const classes = useStyles();

	const handleBack = () => {
		if (data.formaPagamento === 6) {
			return setActiveStep(0);
		}
		[2, 4, 5].includes(data.formaPagamento) ? setActiveStep(0) : setActiveStep(1);
	};

	const handleNext = () => {
		//if ([1, 4].includes(data.formaPagamento)) {
		if (data.formaPagamento === 1) {
			return setActiveStep(3);
		}

		if (data.formaPagamento === 4) {
			return changeStep(3);
		}
		changeStep(4);
	};

	return (
		<Grid container justifyContent="flex-end" spacing={2} direction="row" className={classes.stepActions}>
			<Grid item xs={12} sm={12} md={6} lg={3}>
				<Button className={classes.backButton} onClick={handleBack}>
					Voltar
				</Button>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={3}>
				<Button className={classes.nextButton} onClick={handleNext}>
					Continuar
				</Button>
			</Grid>
		</Grid>
	);
}

function MetodoPagamento({ errors, data, setData, changeStep, setActiveStep, split }) {
	const classes = useStyles();
	const canUseBolepix = useSelector(state =>
		Isset(() => state.system.params['48'].valor) ? state.system.params['48'].valor : false
	);

	const [focusCartao, setFocusCartao] = useState('number');

	const [maxInstallments, setMaxInstallments] = useState(12);
	const token = useSelector(store => store.auth.token);

	useEffect(() => {
		const getMaxInstallments = async () => {
			const response = await Axios.get(`${config.get('apiUrl')}planos_de_vendas/get-parcelamento-maximo`, {
				headers: { Authorization: `Bearer ${token}` },
			});

			setMaxInstallments(response.data.max);
		};

		getMaxInstallments();
	}, [token]);

	// const parcelas = useCallback(() => {
	// 	const result = [];
	// 	const valorCartao = data.valorCartao / 100;

	// 	for (let parcela = 1; parcela <= maxInstallments; parcela++) {
	// 		const text = valorCartao !== 0 ? `${parcela}x de ${Formatter.Real(valorCartao / parcela)}` : `${parcela}x`;

	// 		result.push({ id: parcela, name: text });
	// 	}

	// 	return result;
	// });

	return (
		<>
			{(() => {
				if (data.formaPagamento === 1) {
					return (
						<>
							<Title title="Detalhes do Pagamento" />

							<Grid container justifyContent="center" spacing={2}>
								<Grid container item xs={12} justifyContent="center" spacing={2}>
									<Grid item xs={12} sm={4} md={3} lg={4}>
										<TextField
											className={classes.fullWidth}
											error={errors.valorBoleto}
											label="Valor"
											placeholder="Valor mínimo: R$ 5,00"
											value={Mask.Real(data.valorBoleto)}
											onChange={e => {
												const value = e.target.value;
												setData(data => ({
													...data,
													valorBoleto: Mask.OnlyNumber(value) / 100,
												}));
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={4} md={3} lg={4}>
										<ZDatePicker
											textField
											label="Data de Vencimento"
											erro={errors.dataVencimento}
											date={data.dataVencimento}
											minDate={new Date()}
											onChange={date => setData({ ...data, dataVencimento: date })}
										/>
									</Grid>
									<Grid item xs={12} sm={4} md={3} lg={4}>
										<ZDatePicker
											textField
											date={data.dataLimite}
											label="Data Expiração"
											erro={errors.dataLimite}
											minDate={data.dataVencimento}
											onChange={date => setData({ ...data, dataLimite: date })}
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} justifyContent="center" spacing={2}>
									<Grid item xs={12} sm={12} md={9} lg={12}>
										<TextField
											className={classes.fullWidth}
											label="Descrição"
											value={data.descricao}
											onChange={e => setData({ ...data, descricao: e.target.value })}
										/>
									</Grid>
								</Grid>
								{canUseBolepix && (
									<Grid container item xs={12} justifyContent="center" spacing={2}>
										<Grid item xs={12} sm={12} md={9} lg={12}>
											<Typography>Bolepix</Typography>
											<Switch
												checked={data.bolepix}
												onChange={e => setData({ ...data, bolepix: e.target.checked })}
												name="checkedA"
												inputProps={{ 'aria-label': 'secondary checkbox' }}
											/>
										</Grid>
									</Grid>
								)}

								<Buttons changeStep={changeStep} setActiveStep={setActiveStep} data={data} />
							</Grid>
						</>
					);
				}

				if (data.formaPagamento === 2) {
					return (
						<LinkPagamento
							split={split}
							disablePaper={0}
							disableTitle={true}
							inNovaVenda={true}
							setActiveStepNovaVenda={setActiveStep}
						/>
					);
				}

				if (data.formaPagamento === 3 || data.formaPagamento === 6) {
					return (
						<>
							<Title title="Detalhes do Pagamento" />

							<Grid container justifyContent="center" spacing={2} style={{ marginTop: 30 }}>
								<Grid item xs={12} sm={10} md={6} lg={5}>
									<Cards
										number={data.numeroCartao}
										name={data.titularCartao}
										expiry={data.vencimentoCartao}
										cvc={data.cvcCartao}
										focused={focusCartao}
									/>
								</Grid>
								<Grid item md={6}>
									<Grid container justifyContent="center" spacing={2}>
										<Grid item xs={6} sm={4} md={6} lg={5}>
											<TextField
												className={classes.fullWidth}
												error={errors.valorCartao}
												label="Valor"
												value={Mask.Real(data.valorCartao)}
												onChange={e => {
													const value = e.target.value;
													setData({
														...data,
														valorCartao: Mask.OnlyNumber(value) / 100,
													});
												}}
											/>
										</Grid>
										<Grid item xs={6} sm={5} md={6} lg={5}>
											<FormControl className={classes.fullWidth} error={errors.parcelasCartao}>
												<InputLabel>Parcelas</InputLabel>
												<Select
													className={classes.fullWidth}
													value={data.parcelasCartao}
													onChange={e => setData({ ...data, parcelasCartao: e.target.value })}
												>
													{data.valorCartao > 0 ? (
														new Array(maxInstallments).fill('').map((parcela, i) => (
															<MenuItem key={i} value={++i}>
																{`${i}x de ${Formatter.Real(data.valorCartao / i)}`}
															</MenuItem>
														))
													) : (
														<MenuItem value="">Informe o Valor</MenuItem>
													)}
												</Select>
											</FormControl>
										</Grid>
										<Grid item xs={12} sm={5} md={6} lg={10}>
											<TextField
												className={classes.fullWidth}
												error={errors.titularCartao}
												label="Titular"
												value={data.titularCartao}
												onFocus={() => setFocusCartao('name')}
												onChange={e => setData({ ...data, titularCartao: e.target.value })}
											/>
										</Grid>
										<Grid item xs={12} sm={4} md={6} lg={10}>
											<TextField
												className={classes.fullWidth}
												error={errors.numeroCartao}
												label="Número do Cartão"
												value={Mask.Cartao(data.numeroCartao)}
												onFocus={() => setFocusCartao('number')}
												onChange={e =>
													setData({
														...data,
														numeroCartao: Mask.OnlyNumber(Mask.Cartao(e.target.value)),
													})
												}
											/>
										</Grid>

										<Grid item xs={6} sm={3} md={3} lg={5}>
											<TextField
												className={classes.fullWidth}
												error={errors.vencimentoCartao}
												label="Vencimento"
												value={Mask.ValidadeCartao(data.vencimentoCartao)}
												onFocus={() => setFocusCartao('expiry')}
												onChange={e =>
													setData({
														...data,
														vencimentoCartao: Mask.OnlyNumber(
															Mask.ValidadeCartao(e.target.value)
														),
													})
												}
											/>
										</Grid>
										<Grid item xs={6} sm={3} md={4} lg={5}>
											<TextField
												className={classes.fullWidth}
												error={errors.cvcCartao}
												label={'CVV'}
												value={data.cvcCartao}
												onFocus={() => setFocusCartao('cvc')}
												onBlur={() => setFocusCartao('number')}
												onChange={e =>
													setData({ ...data, cvcCartao: e.target.value.slice(0, 4) })
												}
											/>
										</Grid>
									</Grid>
								</Grid>

								<Buttons changeStep={changeStep} setActiveStep={setActiveStep} data={data} />
							</Grid>
						</>
					);
				}

				if (data.formaPagamento === 4) {
					// Carnê
					return (
						<>
							<Title title="Detalhes do Carnê" />

							<Grid container justifyContent="center" spacing={2}>
								<Grid container item xs={12} justifyContent="center" spacing={2}>
									<Grid item xs={12} sm={12} md={4} lg={4}>
										<TextField
											className={classes.fullWidth}
											error={errors.titulo}
											label="Título"
											value={data.titulo}
											onChange={e => setData({ ...data, titulo: e.target.value })}
										/>
									</Grid>
									<Grid item xs={12} sm={12} md={8} lg={8}>
										<TextField
											className={classes.fullWidth}
											label="Descrição"
											value={data.descricao}
											onChange={e => setData({ ...data, descricao: e.target.value })}
										/>
									</Grid>
								</Grid>
								<Grid container item xs={12} justifyContent="center" spacing={2}>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<TextField
											className={classes.fullWidth}
											error={errors.valorCarne}
											label="Valor"
											placeholder="Valor mínimo: R$ 5,00"
											value={Mask.Real(data.valorCarne)}
											onChange={e => {
												const value = e.target.value;
												setData(data => ({
													...data,
													valorCarne: Mask.OnlyNumber(value) / 100,
												}));
											}}
										/>
									</Grid>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<FormControl className={classes.fullWidth} error={errors.parcelasCarne}>
											<InputLabel>Parcelas</InputLabel>
											<Select
												error={errors.parcelasCarne}

												placeholder="Valor minimo de parcela é 5"

												className={classes.fullWidth}
												value={data.parcelasCarne}
												onChange={e => setData({ ...data, parcelasCarne: e.target.value })}
											>
												{[
													1,
													2,
													3,
													4,
													5,
													6,
													7,
													8,
													9,
													10,
													11,
													12,
													13,
													14,
													15,
													16,
													17,
													18,
													19,
													20,
													21,
													22,
													23,
													24,
												].map((parcela, i) => (
													<MenuItem key={i} value={parcela}>
														{`${parcela}x de ${Formatter.Real(data.valorCarne / parcela)}`}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<ZDatePicker
											textField
											label="Primeiro Vencimento"
											erro={errors.dataVencimento}
											date={data.dataVencimento}
											minDate={new Date()}
											onChange={date => setData({ ...data, dataVencimento: date })}
										/>
									</Grid>
								</Grid>

								<Buttons changeStep={changeStep} setActiveStep={setActiveStep} data={data} />
							</Grid>
						</>
					);
				}

				if (data.formaPagamento === 5 || data.formaPagamento === 9) {
					// PIX
					return (
						<>
							<Title title="Detalhes do Pagamento" />

							<Grid container justifyContent="center" spacing={2}>
								<Grid container item xs={12} justifyContent="center" spacing={2}>
									<Grid item xs={12} sm={4} md={4} lg={4}>
										<TextField
											className={classes.fullWidth}
											error={errors.valorPix}
											label="Valor"
											// placeholder="Valor mínimo: R$ 1,00"
											value={Mask.Real(data.valorPix)}
											onChange={e => {
												const value = e.target.value;
												setData(data => ({
													...data,
													valorPix: Mask.OnlyNumber(value) / 100,
												}));
											}}
										/>
									</Grid>

									<Grid item xs={12} sm={8} md={8} lg={8}>
										<TextField
											className={classes.fullWidth}
											label="Descrição"
											value={data.descricao}
											onChange={e => setData({ ...data, descricao: e.target.value })}
										/>
									</Grid>
								</Grid>

								<Buttons
									split={split}
									changeStep={changeStep}
									setActiveStep={setActiveStep}
									data={data}
								/>
							</Grid>
						</>
					);
				}
			})()}
		</>
	);
}

function FormaPagamento({ data, setData, setActiveStep }) {
	const usuario = useSelector(store => store.auth.usuario);
	const carne = useSelector(store =>
		Isset(() => store.system.params['31'].valor) ? store.system.params['31'].valor : null
	);
	const canCarne = carne && [1, 2, 3, 7].includes(usuario.grupo_id);
	const history = useHistory();

	const linkPagamento = useSelector(store =>
		Isset(() => store.system.params['11'].valor) ? store.system.params['11'].valor : null
	);
	const canSale = Isset(() => usuario.estabelecimento.config.can_sale)
		? !!+usuario.estabelecimento.config.can_sale
		: true;

	const classes = useStyles();
	const canUseNupay = useSelector(store =>
		Isset(() => store.system.params['49'].valor) ? store.system.params['49'].valor : null
	);
	const canVendaOnline = useSelector(store =>
		Isset(() => store.system.params['21'].valor) ? store.system.params['21'].valor : null
	);
	const canPix = useSelector(store =>
		Isset(() => store.system.params['35'].valor) ? store.system.params['35'].valor : null
	);
	const desabilitarBoleto = useSelector(store =>
		Isset(() => store.system.params['46'].valor) ? store.system.params['46'].valor : null
	);
	const isElo7 = usuario.estabelecimento.marketplace.id === 129;

	const handleBoleto = () => {
		setData({ ...data, formaPagamento: 1 });

		setActiveStep(1);
	};

	const handleCartao = () => {
		setData({ ...data, formaPagamento: 3 });

		setActiveStep(1);
	};

	const handleLink = () => {
		setData({ ...data, formaPagamento: 2 });

		setActiveStep(2);
	};
	const handleCarne = () => {
		history.push(`/carnes/new`);
		setData({
			...data,
			formaPagamento: 4,
		});
	};
	const handlePix = () => {
		setData({ ...data, formaPagamento: 5 });

		setActiveStep(1);
	};
	const handleCartaoSemCadastro = () => {
		setData({ ...data, formaPagamento: 6 }); // cartao sem dados do cliente

		setActiveStep(2); // pula direto pro step de pagamento
	};
	const handleNuPay = () => {
		setData({ ...data, formaPagamento: 9 });
		setActiveStep(1);
	};

	return (
		<>
			<Title title="Método de Pagamento" />
			<Grid container justifyContent="center" spacing={3}>
				{!isElo7 && (
					<>
						{!desabilitarBoleto && (
							<Grid item xs={12} sm md lg>
								<div
									className={clsx(classes.formaPagamento, {
										selected: data.formaPagamento === 1,
									})}
									onClick={handleBoleto}
								>
									Boleto Bancário
								</div>
							</Grid>
						)}
						{canVendaOnline && (
							<Grid item xs={12} sm md lg>
								<div
									className={clsx(classes.formaPagamento, {
										selected: data.formaPagamento === 3,
									})}
									onClick={handleCartao}
								>
									Cartão de Crédito
								</div>
							</Grid>
						)}
						{linkPagamento && canSale && (
							<Grid item xs={12} sm md lg>
								<div
									className={clsx(classes.formaPagamento, {
										selected: data.formaPagamento === 2,
									})}
									onClick={handleLink}
								>
									Link de Pagamento
								</div>
							</Grid>
						)}
						{(canPix || isElo7) && (
							<Grid item xs={12} sm md lg>
								<div
									className={clsx(classes.formaPagamento, {
										selected: data.formaPagamento === 5,
									})}
									onClick={handlePix}
								>
									PIX
								</div>
							</Grid>
						)}
						{/* {!isElo7 && (
							<Grid item xs={12} sm md lg>
								<div
									className={clsx(classes.formaPagamento, {
										selected: data.formaPagamento === 6,
									})}
									onClick={handleCartaoSemCadastro}
								>
									Cartão de Crédito s/Cadastro
								</div>
							</Grid>
						)} */}
					</>
				)}
				{isElo7 && (
					<Grid item xs={12} sm md lg>
						<div
							className={clsx(classes.formaPagamento, {
								selected: data.formaPagamento === 5,
							})}
							onClick={handlePix}
						>
							PIX
						</div>
					</Grid>
				)}
				{canCarne && (
					<Grid item xs={12} sm md lg>
						<div className={clsx(classes.formaPagamento)} onClick={handleCarne}>
							Carnê
						</div>
					</Grid>
				)}

				{canUseNupay && (
					<Grid item xs={12} sm md lg>
						<div
							className={clsx(classes.formaPagamento, {
								selected: data.formaPagamento === 9,
							})}
							onClick={handleNuPay}
						>
							NuPay
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
}

export { FormaPagamento, MetodoPagamento };
