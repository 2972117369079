import React, { useState, useEffect } from 'react';
import ZSaveButton from '../system/ZSaveButton';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import { login, checkLogin } from '../../store/auth';
import { useSetRecoilState } from 'recoil';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import { authTokenState } from '../../recoil/atoms';
import axios from 'axios';
import { Isset } from '../../utils';
import { addMessage } from '../../store/alert';
import { getConfig } from '../../store/system';
import { useSelector, useDispatch } from 'react-redux';
import ZAlert from '../system/ZAlert';
import config from 'react-global-configuration';
import { useHistory } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
	'@global': {
		body: {
			backgroundColor: theme.palette.common.white,
		},
	},
	paper: {
		marginTop: theme.spacing(12),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		width: '100%',
		// backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	links: {
		color: 'black',
		//color: theme.palette.primary.main,
	},
}));
export default props => {
	const dispatch = useDispatch();
	const setAuthTokenState = useSetRecoilState(authTokenState);
	let params = props.location.search.split('?');
	let tokenSenha = params[1].split('=')[1];
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [otp, setOtp] = useState();
	const systemConfig = useSelector(store => store.system.config);
	const logado = useSelector(store => store.auth.logado);
	const message = useSelector(store => store.alert);
	const classes = useStyles();
	const {
		estabelecimentoConfig: { primary_color },
	} = systemConfig;
	const handleSubmit = async () => {
		if (otp.length >= 7) {
			setIsLoading(false);
			dispatch(addMessage({ type: 'error', message: 'O codigo deve ser somente seis digitos!' }));
			return;
		}
		setIsLoading(true);
		try {
			const res = await axios.post(config.get('apiUrl') + 'two-factor', {
				id: tokenSenha,
				accessToken: otp,
			});
			if (res.data.success) {
				setIsLoading(false);
				await dispatch(addMessage({ type: 'success', message: 'Código confirmado com sucesso!' }));
				setAuthTokenState(res.data.usuario.token);
				dispatch(login(res.data.usuario));

				const check = await dispatch(checkLogin());

				if (Isset(() => check.usuario.estabelecimento.id)) {
					await dispatch(getConfig(check.usuario.estabelecimento.id));
				} else {
					setIsLoading(false);
					//					dispatch(addMessage({ type: 'success', message: res.data.message }));
				}
			} else {
				setIsLoading(false);
				dispatch(
					addMessage({ type: 'error', message: res.data.message ? res.data.message : 'Codigo invalido' })
				);
			}
		} catch (error) {
			setIsLoading(false);
			dispatch(addMessage({ type: 'error', message: error.response.message }));
			console.error(error);
		}
	};
	if (logado) {
		history.push('/');
	}

	useEffect(() => {
		//dispatch(addMessage({ type: 'success', message: 'Um codigo foi enviado ao seu email' }));
		dispatch(checkLogin());
	}, []);
	return (
		<div style={{ background: systemConfig.backgroundColor }}>
			<Container maxWidth="md" style={{ height: '100vh' }}>
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ height: '100%' }}>
					<Grid item xs={12} style={{ background: systemConfig.backgroundColor, padding: 20 }}>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Grid item xs={12} sm={6} lg={6} style={{}}>
								<Grid container direction="row" justifyContent="center" alignItems="center">
									<Grid item xs={12} style={{ padding: 30 }} align="center">
										<Link href={systemConfig.website} color="inherit">
											<img
												src={systemConfig.logoUrl}
												style={{ maxWidth: '100%' }}
												alt={`Logo ${systemConfig.nome}`}
												title={`Logo ${systemConfig.nome}`}
											/>
										</Link>
									</Grid>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								lg={6}
								style={{ background: 'white', padding: 25, borderRadius: 5 }}
							>
								<form
									className={classes.form}
									noValidate
									onSubmit={e => {
										e.preventDefault();
									}}
								>
									<TextField
										variant="outlined"
										margin="normal"
										required
										fullWidth
										id="otp"
										label="Código"
										name="otp"
										autoComplete="Código"
										autoFocus
										onChange={e => setOtp(e.target.value)}
									// onChange={e => {
									// 	setEmail(e.target.value);
									// }}
									// value={email}
									/>

									<ZSaveButton
										onClick={() => handleSubmit()}
										style={{ background: primary_color }}
										align="right"
										// type="submit"
										label={'verificar codigo'}
										loading={isLoading}
									/>
								</form>
								<Grid container style={{ marginTop: 2 }}>
									<Grid item xs>
										{otp?.length >= 7 ? (
											<p style={{ color: 'red', fontWeight: 'bold', fontSize: '14px' }}>
												O codigo é somente 6 digitos
											</p>
										) : null}
									</Grid>
								</Grid>

								<Box mt={5}>
									<Typography variant="body2" color="textSecondary" align="center">
										Sistema de Pagamentos
										<br />
										{`© ${systemConfig.nome} ` + moment().format('YYYY')}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{message.message.length > 0 && (
					<Grid item xs={12}>
						<ZAlert type={message.type} message={message.message} time={moment().unix()} vertical="top" />
					</Grid>
				)}
			</Container>
		</div>
	);
};
