/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import {
	Grid,
	Typography,
	Container,
	Paper,
	makeStyles,
	CircularProgress,
	Button,
	useMediaQuery,
	useTheme,
	SvgIcon,
} from '@material-ui/core';
import ZCard from '../system/ZCard';
import ZText from '../system/ZText';
import { FiShoppingBag } from 'react-icons/fi';
import { FaRegCreditCard, FaBarcode, FaQrcode } from 'react-icons/fa';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ZSelect from '../system/ZSelect';
import ZAlert from '../system/ZAlert';
import { Mask, ValidatorForm, Isset } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { addMessage } from '../../store/alert';
import config from 'react-global-configuration';
import axios from 'axios';

// import useReactRouter from 'use-react-router';
import moment from 'moment';
import ZLoader from '../system/ZLoader';

const useStyles = makeStyles(theme => ({
	paper: {
		padding: 20,
		marginTop: 48,
		marginBottom: 32,
	},
	greenContainer: {
		backgroundColor: '#50c878',
		width: '100%',
		minHeight: '50vh',
		display: 'flex',

		'& .container__icon': {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			marginTop: '64px',

			position: 'relative',
			backgroundColor: '#FFF',
			borderRadius: '50%',
			width: '100px',
			height: '100px',
		},

		'& .container__icon-check': {
			position: 'absolute',
			top: '66px',
			left: '79px',
		},

		'& .paper': {
			borderRadius: 5,
			padding: 32,
			position: 'absolute',
			// top: '55%',

			'& .paper__content-title': {
				color: '#424242',
				fontSize: '18px',
				fontWeight: 'bold',
				margin: 0,
			},

			'& .paper__content-text': {
				color: '#757575',
				fontSize: '14px',
				lineHeight: '24px',
				margin: 0,
			},
		},
	},
	button: {
		backgroundColor: '#2a9df4',
		color: '#fff',
		padding: '12px 8px',
		'&:hover': {
			backgroundColor: '#187bcd',
		},
	},
	backButton: {
		backgroundColor: 'transparent',
		color: '#777',
		border: '1px solid #2a9df4',
		padding: '12px 8px',
		'&:hover': {
			color: '#fff',
			backgroundColor: '#2a9df4',
		},
	},
	displayMobile: {
		[theme.breakpoints.down('xs')]: {
			flexDirection: 'column-reverse',
		},
	},
}));

export default props => {
	// const { history, match } = useReactRouter();
	const classes = useStyles();
	const theme = useTheme();
	const match = useMediaQuery(theme.breakpoints.down('xs'));

	const dispatch = useDispatch();

	const validateFields = {
		cliente: {
			nome: {
				required: true,
			},
			email: {
				required: true,
				email: true,
			},
			dataNascimento: {
				required: true,
			},
			cpf: {
				required: true,
			},
		},
		endereco: {
			logradouro: {
				required: true,
			},
			numero: {
				required: true,
			},
			cep: {
				required: true,
			},
			cidade: {
				required: true,
			},
			estado: {
				required: true,
			},
		},
		cartao: {
			titular: {
				required: true,
			},
			validade: {
				required: true,
			},
			numero: {
				required: true,
			},
			codigoSeguranca: {
				required: true,
			},
		},
	};

	const message = useSelector(state => state.alert);
	const [text, setText] = useState('Carregando...');
	const [loading, setLoading] = useState(false);
	const [onError, setOnError] = useState(false);
	const [planoAssinado, setPlanoAssinado] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [inputText, setInputText] = useState('Assinar');
	const [loadingEndereco, setLoadingEndereco] = useState(false);
	// const [dataAdesao, setAdesao] = useState({"planoId":"4","cliente":{"nome":"Teste2","email":"dafdsf@hotmail.com","dataNascimento":"1991-12-26","cpf":"19860489009","telefone":"1421421241","celular":"14241241241"},"endereco":{"logradouro":"Rua Leoneta","numero":"4","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":""},"cartao":{"titular":"Dayglor Campos","validade":"02/07","numero":"5234211181847112","codigoSeguranca":"069"}});
	const [dataAdesao, setAdesao] = useState({
		planoId: props.planoId,
		tipoEstabelecimentoId: 1,
		due_day: '',
		assinaturaId: false,
		socio: '',
		socioCpf: '',
		cliente: {
			nome: '',
			email: '',
			dataNascimento: '',
			cpf: '',
			cnpj: '',
			telefone: '',
			celular: '',
			cartaoAntigo: {
				nome_titular: '',
				ultimos_digitos: '',
				mes_expiracao: '',
				bandeira: '',
				ano_expiracao: '',
			},
		},
		endereco: {
			logradouro: '',
			complemento: '',
			numero: '',
			cep: '',
			cidade: '',
			estado: '',
		},
		cartao: {
			titular: '',
			validade: '',
			numero: '',
			codigoSeguranca: '',
		},
	});
	// const [dataAdesao, setAdesao] = useState({"planoId":"3","cliente":{"nome":"f dsa fdsaf","email":"fdafdsa@hotmail.com","dataNascimento":"1991-12-26","cpf":"74575174050","telefone":"1124141412","celular":"41241241212"},"endereco":{"logradouro":"Rua Leoneta","numero":"421","cep":"03380235","cidade":"São Paulo","estado":"SP","complemento":""},"cartao":{"titular":"Titular Cartão","validade":"12/23","numero":"5346955803456653","codigoSeguranca":"124"}});
	const [cepAux, setCepAux] = useState('');
	const [data, setData] = useState({
		name: '',
		description: '',
		setup_amount: 0.0,
		amount: 0.0,
		grace_period: '',
		tolerance_period: '',
		frequency: '',
		zoop_plano_id: null,
		interval: 1,
		estabelecimento: {},
		logo: false,
		payment_methods: '',
	});

	const token = useSelector(store => store.auth.token);
	const setDataSekron = async dataSekron => {
		var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + dataSekron.endereco.cep);

		const endereco = res.data.endereco;

		setAdesao({
			...dataAdesao,
			assinaturaId: dataSekron.assinatura_id,
			tipoEstabelecimentoId: dataSekron.cpf ? 1 : 2,
			cliente: {
				nome: dataSekron.nome,
				email: dataSekron.email || '',
				dataNascimento: dataSekron.data_nascimento || '',
				cpf: dataSekron.cpf,
				cnpj: dataSekron.cnpj,
				telefone: dataSekron.telefone,
				celular: dataSekron.celular || '',
				cartaoAntigo: {
					...dataSekron.cartaoAntigo,
				},
			},
			endereco: {
				logradouro: endereco.logradouro,
				numero: dataSekron.endereco.numero,
				cep: dataSekron.endereco.cep,
				cidade: endereco.localidade || '',
				estado: endereco.uf,
				complemento: dataSekron.endereco.complemento,
			},
		});
	};

	// const estabelecimento = useSelector(store => store.auth.estabelecimento);
	useEffect(() => {
		if (props.planoId) {
			let url = config.get('apiUrl') + 'planos/' + props.planoId + '/';
			if (props.isSekron) {
				url += 'sekron';
			}

			axios
				.get(url, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => {
					if (res.data.success) {
						if (
							res.data.plano.plan_expiration_date &&
							moment(res.data.plano.plan_expiration_date) < moment()
						) {
							setText('Esse plano está expirado.');
						} else {
							setData({
								name: res.data.plano.name,
								description: res.data.plano.description,
								setup_amount: res.data.plano.setup_amount / 100,
								amount: res.data.plano.amount / 100,
								grace_period: res.data.plano.grace_period,
								tolerance_period: res.data.plano.tolerance_period,
								frequency: res.data.plano.frequency,
								zoop_plano_id: res.data.plano.zoop_plano_id,
								interval: res.data.plano.interval,
								logo: !!res.data.plano.logo,
								estabelecimento: res.data.plano.estabelecimento,
								payment_methods: res.data.plano.method,
								due_days: res.data.plano.due_days,
							});

							if (props.isSekron && res.data.cliente) {
								let dataSekron = res.data.cliente;

								if (res.data.cliente.assinatura_id) {
									setInputText('Atualizar Cartão');
								}

								setDataSekron(dataSekron);
							}
						}
					} else {
						setText('Plano não encontrado.');
					}
				});
		}

		if (props.subscriptionId) {
			const url = config.get('apiUrl') + 'assinatura/token/' + props.subscriptionId;
			setInputText('Atualizar cartão');

			axios
				.get(url, {
					headers: { Authorization: `Bearer ${token}` },
				})
				.then(res => {
					if (res.data.success) {
						const plano = res.data.data.plano;
						const assinatura = res.data.data;
						const cliente = res.data.data.cliente;

						setData({
							name: plano.name,
							description: plano.description,
							setup_amount: plano.setup_amount / 100,
							amount: plano.amount / 100,
							grace_period: plano.grace_period,
							tolerance_period: plano.tolerance_period,
							frequency: plano.frequency,
							zoop_plano_id: plano.zoop_plano_id,
							interval: plano.interval,
							logo: !!plano.logo,
							estabelecimento: plano.estabelecimento,
							payment_methods: res.data.data.payment_method,
						});

						setAdesao({
							...dataAdesao,
							planoId: plano.internal_id,
							assinaturaId: assinatura.id,
							tipoEstabelecimentoId: cliente.clientes_documentos[0].tipo_documento_id === 2 ? 1 : 2,
							cliente: {
								nome: cliente.nome,
								email: cliente.email,
								dataNascimento: cliente.data_nascimento,
								cpf: cliente.clientes_documentos[0].documento,
								cnpj: cliente.clientes_documentos[0].documento,
								telefone: cliente.clientes_contatos.find(value => value.tipo_contato_id === 1)?.contato,
								celular: cliente.clientes_contatos.find(value => value.tipo_contato_id === 2).contato,
								cartaoAntigo: {
									...cliente.clientes_cartoes[0],
								},
							},
							endereco: {
								logradouro: cliente.endereco.logradouro,
								numero: cliente.endereco.numero,
								cep: cliente.endereco.cep,
								cidade: cliente.endereco.cidade,
								estado: cliente.endereco.uf,
								complemento: cliente.endereco.complemento,
							},
						});

						setCepAux(cliente.endereco.cep);

						// if (props.isSekron && res.data.cliente) {
						// 	let dataSekron = res.data.cliente;

						// 	if (dataSekron.NOME) {
						// 		setDataSekron(dataSekron);
						// 	}
						// }
					} else {
						setText('Assinatura não encontrado.');
					}
				});
		}
	}, []);

	const isDueDayRequired = useMemo(() => {
		return Boolean(data.due_days && data.frequency === 'monthly');
	}, [data.due_days, data.frequency]);

	const formattedDueDays = useMemo(() => {
		let result = [];
		let month = moment().format('MM');
		const actualDay = moment().format('DD');
		const hasDueDays = Boolean(data.due_days);

		if (hasDueDays) {
			result = data.due_days
				.sort((a, b) => a - b)
				.map(days => {
					if (Number(days) < Number(actualDay)) {
						month = moment()
							.add(1, 'months')
							.format('MM');
					}

					return { id: Number(days), name: `Dia ${days}/${month}` };
				});
		}

		return result;
	}, [data.due_days]);

	const handleSubmitForm = async () => {
		dispatch(addMessage({ type: 'error', message: '' }));
		setLoading(true);
		try {
			if (isDueDayRequired) {
				validateFields.due_day = {
					required: true,
				};
			}

			if (dataAdesao.tipoEstabelecimentoId === 2) {
				delete validateFields.cliente.cpf;
				validateFields.cliente.cnpj = {
					required: true,
				};
			} else {
				delete validateFields.cliente.cnpj;
				validateFields.cliente.cpf = {
					required: true,
				};
			}

			if (['boleto', 'pix'].includes(data.payment_methods)) delete validateFields.cartao;

			ValidatorForm(validateFields, dataAdesao);
		} catch (erro) {
			dispatch(addMessage({ type: 'error', message: 'Existem campos inválidos no formulário' }));
			setLoading(false);
			setOnError(true);
			setActiveStep(0);
			return false;
		}

		let res = '';
		var dadosAssinatura = dataAdesao;
		if (dataAdesao.tipoEstabelecimentoId === 2) {
			delete dadosAssinatura.cliente.cpf;
		} else {
			delete dadosAssinatura.cliente.cnpj;
		}

		res = await axios.post(config.get('apiUrl') + 'planos/assinar', dadosAssinatura, {
			headers: { Authorization: `Bearer ${token}` },
		});

		if (res.data.error) {
			dispatch(addMessage({ type: 'error', message: res.data.error.message }));
			setLoading(false);
			setPlanoAssinado(false);
		}

		if (Isset(() => res.data.success) && res.data.success === true) {
			dispatch(addMessage({ type: 'success', message: 'Operação realizada com sucesso' }));

			setPlanoAssinado(true);

			if (!dataAdesao.assinaturaId) {
				setText('Assinatura realizada com sucesso!');
			} else {
				setText('Cartão atualizado com sucesso!');
			}
			setData({ ...data, name: '' });
		}
		setLoading(false);
	};

	const getEndereco = async value => {
		if (value.length === 8) {
			setLoadingEndereco(true);

			// var res = await axios.get(config.get('apiUrl') + 'buscar_cep/' + value);
			var res = await axios.get(`https://viacep.com.br/ws/${value}/json/`);
			if (res.status !== 200) {
				setAdesao({
					...dataAdesao,
					endereco: {
						...dataAdesao.endereco,
						cep: value,
						logradouro: '',
						cidade: '',
						estado: '',
						complemento: '',
					},
				});
				return false;
			}

			setAdesao({
				...dataAdesao,
				endereco: {
					...dataAdesao.endereco,
					cep: Mask.OnlyNumber(res.data.cep),
					logradouro: res.data.logradouro,
					cidade: res.data.localidade,
					estado: res.data.uf,
					complemento: res.data.complemento,
				},
			});

			setLoadingEndereco(false);
		}
	};

	const handleNext = () => {
		setActiveStep(prevStep => prevStep + 1);
	};

	const handleBack = () => {
		setActiveStep(prevStep => prevStep - 1);
	};

	const maskCreditCard = useMemo(() => {
		const regex = /\b(?:\d{4}[ -]?){3}(?=\d{4}\b)/gm;
		const subst = `**** **** **** `;

		return dataAdesao.cartao.numero.replace(regex, subst);
	}, [dataAdesao.cartao.numero]);

	const frequency = useMemo(() => {
		let frequency = '';

		switch (data.frequency) {
			case 'monthly':
				frequency = 'mês';
				if (data.interval > 1) {
					frequency = 'meses';
				}
				break;
			case 'annually':
				frequency = 'ano';
				if (data.interval > 1) {
					frequency = 'anos';
				}
				break;
			case 'weekly':
				frequency = 'semana';
				if (data.interval > 1) {
					frequency = 'semanas';
				}
				break;
			case 'daily':
				frequency = 'dia';
				if (data.interval > 1) {
					frequency = 'dias';
				}
				break;
			default:
				break;
		}

		return frequency;
	}, [data.name]);

	const formattedDate = useMemo(() => {
		return moment(dataAdesao.cliente.dataNascimento).format('DD/MM/YYYY');
	}, [dataAdesao.cliente.dataNascimento]);

	const isCPNJFilled = useMemo(() => {
		return Boolean(dataAdesao.cliente.cnpj);
	}, [dataAdesao.cliente.cnpj]);

	if (!data.name) {
		return (
			<>
				{planoAssinado ? (
					<Grid container className={classes.greenContainer}>
						<Grid container item justifyContent="center">
							<div className="container__icon">
								<div>
									<FiShoppingBag color="#059142" size="50px" strokeWidth="1px" />
								</div>

								<div className="container__icon-check">
									<SvgIcon
										component={CheckCircleIcon}
										fontSize="large"
										style={{ color: '#043927' }}
									/>
								</div>
							</div>
							<Grid container item justifyContent="center">
								<section>
									<p
										style={{
											color: '#fff',
											fontSize: '32px',
											margin: 0,
											padding: 10,
											textAlign: 'center',
										}}
									>
										{data.payment_methods === 'boleto' && 'Pronto, seu boleto foi gerado!'}
										{data.payment_methods === 'pix' &&
											'Pronto, foi gerado um link para gerar o QR code!'}
										{['credit', 'credito'].includes(data.payment_methods) &&
											'Assinatura realizada com sucesso!'}
									</p>
								</section>
							</Grid>
						</Grid>
						<Grid container justifyContent="center" alignItems="center" style={{ marginTop: 64 }}>
							<Paper className="paper">
								<Grid className="paper__container-content" item container direction="row" xs={12}>
									<Grid item sm={1} xs={12} style={{ marginRight: 16 }}>
										{data.payment_methods === 'boleto' && <FaBarcode size="32px" />}
										{['credit', 'credito'].includes(data.payment_methods) && (
											<FaRegCreditCard size="32px" />
										)}
										{data.payment_methods === 'pix' && <FaQrcode size="32px" />}
									</Grid>

									<Grid item sm={10}>
										{data.payment_methods === 'boleto' && (
											<p className="paper__content-title">Boleto Bancário</p>
										)}
										{data.payment_methods === 'pix' && <p className="paper__content-title">Pix</p>}
										{['credit', 'credito'].includes(data.payment_methods) && (
											<p className="paper__content-title">
												Será debitado o valor de <b>{Mask.Real(data.amount)}</b> do seu cartão
												de crédito a cada{' '}
												<b>
													{data.interval} {frequency}.
												</b>
												{+data.setup_amount > 1 && (
													<>
														<br />O valor do setup é de{' '}
														<b>{Mask.Real(data.setup_amount)}</b>, cobrado apenas uma vez no
														ato da adesão.
													</>
												)}
											</p>
										)}
										{['credit', 'credito'].includes(data.payment_methods) && (
											<p className="paper__content-text">{maskCreditCard}</p>
										)}
										<p className="paper__content-text">
											{data.payment_methods === 'boleto' &&
												'O boleto foi enviado para o seu e-mail, faça o pagamento o quanto antes pois a compensação pode demorar até 3 dias úteis'}
											{data.payment_methods === 'pix' &&
												'Um link para gerar o QRcode foi enviado para o seu e-mail'}
											{['credit', 'credito'].includes(data.payment_methods) &&
												`Na fatura do seu cartão você verá o pagamento em nome de ${data.estabelecimento.nome_fantasia.toUpperCase()}`}
										</p>
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				) : (
					<div style={{ background: '#e2e2e2' }}>
						<Container maxWidth="md">
							<Grid
								container
								direction="row"
								justifyContent="center"
								alignItems="center"
								style={{ height: '100vh' }}
							>
								<Paper style={{ padding: 45 }}>
									<Grid item xs={12} align="center">
										<Typography variant="h4" gutterBottom>
											{text}
										</Typography>
									</Grid>
								</Paper>
							</Grid>
						</Container>
					</div>
				)}
			</>
		);
	}

	if (data.name) {
		return (
			<div
				style={{
					background: '#e2e2e2',
					minHeight: '100vh',
				}}
			>
				<Container maxWidth="md">
					<Grid container className={classes.displayMobile}>
						{message.message && (
							<ZAlert type={message.type} message={message.message} time={moment().unix()} />
						)}
						<Grid item xs={12} md={6}>
							<Paper className={classes.paper}>
								{activeStep === 0 && (
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography variant="h5" gutterBottom>
												Cliente
											</Typography>
										</Grid>
										<Grid item xs={12} sm={6} md={formattedDueDays.length > 0 ? 6 : 7}>
											<ZSelect
												options={[
													{
														id: 1,
														name: 'Pessoa Física',
													},
													{
														id: 2,
														name: 'Pessoa Jurídica',
													},
												]}
												label="Tipo"
												required={true}
												value={dataAdesao.tipoEstabelecimentoId}
												onChange={e => {
													setAdesao({
														...dataAdesao,
														tipoEstabelecimentoId: e.target.value,
													});
												}}
											/>
										</Grid>
										{formattedDueDays.length > 0 && (
											<Grid item xs={12} sm={6} md={6}>
												<ZSelect
													options={formattedDueDays}
													label={
														data.payment_methods === 'boleto'
															? 'Vencimento do Boleto'
															: 'Vencimento do Pix'
													}
													required={isDueDayRequired}
													onError={onError}
													value={dataAdesao.due_day}
													onChange={e => {
														setAdesao({
															...dataAdesao,
															due_day: e.target.value,
														});
													}}
												/>
											</Grid>
										)}
										<Grid item xs={12} sm={6} md={6}>
											<ZText
												label="Nome"
												onError={onError}
												required={true}
												value={dataAdesao.cliente.nome}
												onBlur={e =>
													setAdesao({
														...dataAdesao,
														cliente: { ...dataAdesao.cliente, nome: e.target.value },
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<ZText
												label="Telefone"
												mask={'(99) 9999-9999'}
												maskChar={null}
												onError={onError}
												// required={true}
												value={dataAdesao.cliente.telefone}
												onBlur={e =>
													setAdesao({
														...dataAdesao,
														cliente: {
															...dataAdesao.cliente,
															telefone: Mask.OnlyNumber(e.target.value),
														},
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<ZText
												label="Celular"
												mask={'(99) 9 9999-9999'}
												maskChar={null}
												onError={onError}
												required={true}
												value={dataAdesao.cliente.celular}
												onBlur={e =>
													setAdesao({
														...dataAdesao,
														cliente: {
															...dataAdesao.cliente,
															celular: Mask.OnlyNumber(e.target.value),
														},
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<ZText
												label="E-mail"
												onError={onError}
												required={true}
												email
												value={dataAdesao.cliente.email}
												onBlur={e =>
													setAdesao({
														...dataAdesao,
														cliente: { ...dataAdesao.cliente, email: e.target.value },
													})
												}
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={6}>
											<ZText
												label="Data Nascimento"
												mask={'99/99/9999'}
												maskChar={null}
												onError={onError}
												required={true}
												value={moment(dataAdesao.cliente.dataNascimento).format('DD/MM/YYYY')}
												onBlur={e => {
													let dataNascimento = moment(e.target.value, 'DD/MM/YYYY').format(
														'YYYY-MM-DD'
													);
													setAdesao({
														...dataAdesao,
														cliente: {
															...dataAdesao.cliente,
															dataNascimento: dataNascimento,
														},
													});
												}}
											/>
										</Grid>
										{dataAdesao.tipoEstabelecimentoId === 1 && (
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="CPF"
													mask={'999.999.999-99'}
													onError={onError}
													required={true}
													value={dataAdesao.cliente.cpf}
													onBlur={e =>
														setAdesao({
															...dataAdesao,
															cliente: {
																...dataAdesao.cliente,
																cpf: Mask.OnlyNumber(e.target.value),
															},
														})
													}
												/>
											</Grid>
										)}
										{dataAdesao.tipoEstabelecimentoId === 2 && (
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="CNPJ"
													mask={'99.999.999/9999-99'}
													onError={onError}
													required={true}
													value={dataAdesao.cliente.cnpj}
													onBlur={e =>
														setAdesao({
															...dataAdesao,
															cliente: {
																...dataAdesao.cliente,
																cnpj: Mask.OnlyNumber(e.target.value),
															},
														})
													}
												/>
											</Grid>
										)}
										{isCPNJFilled && (
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="Nome do Sócio"
													onError={onError}
													required={false}
													value={dataAdesao.socio}
													onBlur={e =>
														setAdesao({
															...dataAdesao,
															socio: e.target.value,
														})
													}
												/>
											</Grid>
										)}
										{isCPNJFilled && (
											<Grid item xs={12} sm={6} md={6}>
												<ZText
													label="CPF do Sócio"
													onError={onError}
													mask={'999.999.999-99'}
													required={false}
													value={dataAdesao.socioCpf}
													onBlur={e =>
														setAdesao({
															...dataAdesao,
															socioCpf: Mask.OnlyNumber(e.target.value),
														})
													}
												/>
											</Grid>
										)}
									</Grid>
								)}

								{activeStep === 1 && (
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Typography variant="h5" gutterBottom>
												Endereço
											</Typography>
										</Grid>
										<Grid container justifyContent="center" style={{ marginBottom: 16 }}>
											<Grid item xs={12} sm={6} md={5}>
												<ZText
													label="CEP"
													mask={'99999-999'}
													onError={onError}
													required={true}
													value={dataAdesao.endereco.cep}
													onBlur={e => {
														let cep = Mask.OnlyNumber(e.target.value);

														setCepAux(cep);

														setAdesao({
															...dataAdesao,
															endereco: { ...dataAdesao.endereco, cep: cep },
														});
														getEndereco(cep);
													}}
												/>
											</Grid>
										</Grid>

										{loadingEndereco ? (
											<ZLoader height={100} size={20} message="Buscando endereço" />
										) : (
											<>
												{cepAux && (
													<React.Fragment>
														<Grid item xs={12} sm={6} md={6}>
															<ZText
																label="Número"
																onError={onError}
																required={true}
																value={dataAdesao.endereco.numero}
																onBlur={e =>
																	setAdesao({
																		...dataAdesao,
																		endereco: {
																			...dataAdesao.endereco,
																			numero: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6} md={6}>
															<ZText
																label="Logradouro"
																onError={onError}
																required={true}
																value={dataAdesao.endereco.logradouro}
																onBlur={e =>
																	setAdesao({
																		...dataAdesao,
																		endereco: {
																			...dataAdesao.endereco,
																			logradouro: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6} md={6}>
															<ZText
																label="Complemento"
																// onError={onError}
																// required={true}
																value={dataAdesao.endereco.complemento}
																onBlur={e =>
																	setAdesao({
																		...dataAdesao,
																		endereco: {
																			...dataAdesao.endereco,
																			complemento: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6} md={6}>
															<ZText
																label="Cidade"
																onError={onError}
																// required={true}
																disabled
																value={dataAdesao.endereco.cidade}
																onBlur={e =>
																	setAdesao({
																		...dataAdesao,
																		endereco: {
																			...dataAdesao.endereco,
																			cidade: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
														<Grid item xs={12} sm={6} md={6}>
															<ZText
																label="Estado"
																onError={onError}
																disabled
																// required={true}
																value={dataAdesao.endereco.estado}
																onBlur={e =>
																	setAdesao({
																		...dataAdesao,
																		endereco: {
																			...dataAdesao.endereco,
																			estado: e.target.value,
																		},
																	})
																}
															/>
														</Grid>
													</React.Fragment>
												)}
											</>
										)}
									</Grid>
								)}

								{activeStep === 2 && (
									<>
										{['credit', 'credito'].includes(data.payment_methods) && (
											<Grid container spacing={2}>
												{inputText !== 'Assinar' && (
													<>
														<Grid item xs={12} style={{ marginBottom: 16 }}>
															<Typography variant="h5" gutterBottom>
																Cartão Cadastrado
															</Typography>
														</Grid>
														<Grid item xs={6} sm={6} md={6}>
															<span style={{ fontWeight: 'bold', color: '#424242' }}>
																Bandeira:
															</span>{' '}
															<br />
															<span style={{ color: '#777' }}>
																{dataAdesao.cliente.cartaoAntigo.bandeira}
															</span>
														</Grid>
														<Grid item xs={6} sm={6} md={6}>
															<span style={{ fontWeight: 'bold', color: '#424242' }}>
																Titular:
															</span>{' '}
															<br />
															<span style={{ color: '#777' }}>
																{' '}
																{dataAdesao.cliente.cartaoAntigo.nome_titular}{' '}
															</span>
														</Grid>
														<Grid item xs={6} sm={6} md={6}>
															<span style={{ fontWeight: 'bold', color: '#424242' }}>
																Número:
															</span>{' '}
															<br />
															**** **** ****{' '}
															<span style={{ color: '#777' }}>
																{' '}
																{dataAdesao.cliente.cartaoAntigo.ultimos_digitos}{' '}
															</span>
														</Grid>
														<Grid item xs={6} sm={6} md={6}>
															<span style={{ fontWeight: 'bold', color: '#424242' }}>
																Validade:
															</span>{' '}
															<br />
															<span style={{ color: '#777' }}>
																{dataAdesao.cliente.cartaoAntigo.mes_expiracao
																	.toString()
																	.padStart(2, '0')}
																/{dataAdesao.cliente.cartaoAntigo.ano_expiracao || ''}
															</span>
														</Grid>
													</>
												)}
												<Grid item xs={12} style={{ marginTop: 32 }}>
													<Typography variant="h5" gutterBottom>
														Cartão
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<ZCard
														cardGrid={{
															xs: 0,
															md: 0,
															lg: 0,
															marginBottom: 32,
														}}
														containerInputsGrid={{
															md: 12,
															lg: 12,
														}}
														direction={'column'}
														number={dataAdesao.cartao.numero}
														name={dataAdesao.cartao.titular}
														expiry={dataAdesao.cartao.validade}
														cvc={dataAdesao.cartao.codigoSeguranca}
														numberOnBlur={e =>
															setAdesao({
																...dataAdesao,
																cartao: {
																	...dataAdesao.cartao,
																	numero: Mask.OnlyNumber(e.target.value),
																},
															})
														}
														nameOnBlur={e =>
															setAdesao({
																...dataAdesao,
																cartao: {
																	...dataAdesao.cartao,
																	titular: e.target.value,
																},
															})
														}
														expiryOnBlur={e =>
															setAdesao({
																...dataAdesao,
																cartao: {
																	...dataAdesao.cartao,
																	validade: e.target.value,
																},
															})
														}
														cvcOnBlur={e =>
															setAdesao({
																...dataAdesao,
																cartao: {
																	...dataAdesao.cartao,
																	codigoSeguranca: e.target.value,
																},
															})
														}
														onError={onError}
													/>
												</Grid>
											</Grid>
										)}

										{data.payment_methods === 'boleto' && (
											<Grid item xs={12}>
												<Typography variant="h6" gutterBottom>
													Boleto Bancário
												</Typography>
												<Typography
													variant="body2"
													gutterBottom
													style={{ marginTop: 45, marginBottom: 45, lineHeight: 2 }}
												>
													Esse plano tem a forma de pagamento via boleto bancário, ou seja, no
													final do processo você receberá o boleto em seu e-mail. <br />
													Clique em continuar.
												</Typography>
											</Grid>
										)}
										{data.payment_methods === 'pix' && (
											<Grid item xs={12}>
												<Typography variant="h6" gutterBottom>
													Pix
												</Typography>
												<Typography
													variant="body2"
													gutterBottom
													style={{ marginTop: 45, marginBottom: 45, lineHeight: 2 }}
												>
													Esse plano tem a forma de pagamento via pix, ou seja, no final do
													processo você receberá um link em seu e-mail, para gerar o QRcode
													pix. <br />
													Clique em continuar.
												</Typography>
											</Grid>
										)}
									</>
								)}

								{activeStep === 3 && (
									<Grid container direction="row">
										<Grid item xs={12} style={{ marginBottom: 8 }}>
											<Typography variant="h5" gutterBottom>
												Resumo
											</Typography>
										</Grid>
										<Grid item xs={12} md={6}>
											<strong>SEUS DADOS:</strong>
											<Typography variant="subtitle2" gutterBottom>
												<p>
													Nome: <br />
													<b>{dataAdesao.cliente.nome}</b>
												</p>
												{dataAdesao.tipoEstabelecimentoId === 1 && (
													<>
														<p>
															CPF: <br />
															<b>{dataAdesao.cliente.cpf}</b>
														</p>
													</>
												)}
												{dataAdesao.tipoEstabelecimentoId === 2 && (
													<>
														<p>
															CNPJ: <br />
															<b>{dataAdesao.cliente.cnpj}</b>
														</p>
													</>
												)}
												<p>
													Data de Nascimento: <br />
													<b>{formattedDate}</b>
												</p>
												<p>
													Celular: <br />
													<b>{dataAdesao.cliente.celular}</b>
												</p>
												<p>
													E-mail: <br />
													<b>{dataAdesao.cliente.email}</b>
												</p>
												{dataAdesao.due_day && (
													<p>
														Vencimento do
														{data.payment_methods === 'boleto' ? ' Boleto' : ' Pix'}: <br />
														<b>{`Dia ${dataAdesao.due_day}`}</b>
													</p>
												)}
											</Typography>
										</Grid>
										<Grid item xs={12} md={6} className={classes.label}>
											<strong>PAGAMENTO:</strong>
											<Typography variant="subtitle2" gutterBottom>
												<p>
													{data.payment_methods === 'boleto' && 'Boleto Bancário'}
													{['credit', 'credito'].includes(data.payment_methods) &&
														'Cartão de Crédito'}
													{data.payment_methods === 'pix' && 'Pix'}
												</p>
												{/* Preço: <b>{Mask.Real(data.amount)}</b> */}
												<p>
													<span>
														{' '}
														{/* {dataAdesao.parcelas} ve
														{dataAdesao.parcelas > 1 ? 'zes ' : 'z '}
														de {Mask.Real(dataAdesao.valorParcela)} */}
													</span>
												</p>
												{data.amount && (
													<>
														<p>
															{' '}
															Valor total: <br />
															<b>{Mask.Real(data.amount + data.setup_amount)}</b>
														</p>
													</>
												)}
											</Typography>
										</Grid>
									</Grid>
								)}
								<Grid item xs={12} style={{ marginBottom: 50 }}>
									<Grid spacing={2} container direction="row" justifyContent="flex-end">
										<Grid item xs={12} sm={6} md={6}>
											<Button
												disabled={activeStep === 0}
												className={classes.backButton}
												onClick={handleBack}
												fullWidth
											>
												Voltar
											</Button>
										</Grid>
										{activeStep < 3 && (
											<Grid item xs={12} sm={6} md={6}>
												<Button className={classes.button} fullWidth onClick={handleNext}>
													Continuar
												</Button>
											</Grid>
										)}
										{activeStep === 3 && (
											<Grid item xs={12} md={6}>
												<Button
													onClick={() => handleSubmitForm()}
													className={classes.button}
													fullWidth
													disabled={loading}
												>
													{loading ? (
														<CircularProgress size={24} style={{ color: '#fff' }} />
													) : (
														'Concluir'
													)}
												</Button>
											</Grid>
										)}
									</Grid>
								</Grid>
							</Paper>
						</Grid>
						<Grid item xs={12} md={4} style={{ marginLeft: match ? 0 : 48 }}>
							{data.logo && (
								<Grid item xs={12} md={12} align="center">
									<img
										src={data.estabelecimento.logo}
										alt={data.estabelecimento.nome_fantasia}
										title={data.estabelecimento.nome_fantasia}
										style={{ marginTop: '30px', maxWidth: '100%', maxHeight: '170px' }}
									/>
								</Grid>
							)}
							<Paper className={classes.paper}>
								<Grid container direction="column" justifyContent="center" alignItems="center">
									<Grid
										conatainer
										justifyContent="center"
										item
										xs={12}
										md={12}
										style={{ marginBottom: 32, marginTop: 24 }}
									>
										<Typography variant="h5" gutterBottom>
											{data.name}
										</Typography>
										<Typography variant="body2" gutterBottom>
											{data.estabelecimento.nome_fantasia}
										</Typography>
										<Typography
											variant="body2"
											gutterBottom
											style={{ whiteSpace: 'pre-line', color: '#777' }}
										>
											{data.description}
										</Typography>
									</Grid>
								</Grid>

								<Grid item xs={12}>
									<Grid container spacing={5} justifyContent="flex-end">
										{['credit', 'credito'].includes(data.payment_methods) && (
											<Grid item md={12}>
												{inputText === 'Assinar' && (
													<Typography
														variant="subtitle2"
														gutterBottom
														style={{ marginTop: 10, color: '#777' }}
													>
														Será debitado o valor de <b>{Mask.Real(data.amount)}</b> do seu
														cartão de crédito a cada{' '}
														<b>
															{data.interval} {frequency}.
														</b>
														{+data.setup_amount > 1 && (
															<>
																<br />O valor do setup é de{' '}
																<b>{Mask.Real(data.setup_amount)}</b>, cobrado apenas
																uma vez no ato da adesão.
															</>
														)}
													</Typography>
												)}
											</Grid>
										)}

										{data.payment_methods === 'pix' && (
											<Grid item md={12}>
												{inputText === 'Assinar' && (
													<Typography
														variant="subtitle2"
														gutterBottom
														style={{ marginTop: 10, color: '#777' }}
													>
														Será gerado pix recorrentes no valor de{' '}
														<b>{Mask.Real(data.amount)}</b>{' '}
														<b>
															a cada {data.interval} {frequency}.
														</b>
														{+data.setup_amount > 1 && (
															<>
																<br />O valor do setup é de{' '}
																<b>{Mask.Real(data.setup_amount)}</b>, cobrado apenas
																uma vez no ato da adesão.
															</>
														)}
													</Typography>
												)}
											</Grid>
										)}

										{data.payment_methods === 'boleto' && (
											<Grid item md={12}>
												{inputText === 'Assinar' && (
													<Typography
														variant="subtitle2"
														gutterBottom
														style={{ marginTop: 10, color: '#777' }}
													>
														Este plano emitirá boletos recorrentes no valor de{' '}
														<strong> {Mask.Real(data.amount)} </strong>.<br />
														Existe um valor de setup de{' '}
														<strong> {Mask.Real(data.setup_amount)} </strong> que será
														cobrado uma única vez.
													</Typography>
												)}
											</Grid>
										)}
									</Grid>
								</Grid>
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
};
