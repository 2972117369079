import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
// import { Formatter, Isset } from '../../utils';
import { Isset } from '../../utils';
import axios from 'axios';
import config from 'react-global-configuration';
import queryString from 'query-string';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { FaDownload } from 'react-icons/fa';
// import format from 'date-fns/format';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
// import pt from 'date-fns/locale/pt';
import { addMessage } from '../../store/alert';
import { setFilters } from '../../store/filters';
import ZFiltros from '../system/ZFiltros';
import ZLoader from '../system/ZLoader';
import { Button, Tooltip } from '@material-ui/core';
import SemDetalhe from './semDetalhe';
import Detalhado from './detalhado';
import { useHistory } from 'react-router-dom';
import LastExport from '../Export/LastExport';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import ZPagination from '../system/ZPagination';
import moment from 'moment';

const useStyles = makeStyles(theme => {
	return {
		extrato: {
			width: '100%',
			flexGrow: 1,
		},
		header: {
			fontWeight: 700,
			fontSize: 20,
			margin: 8,
		},
		filtros: {
			position: 'relative',
			borderRadius: 4,
			marginBottom: 30,
			padding: '5px 10px',
			display: 'flex',
			alignItems: 'center',
		},
		export: {
			position: 'absolute',
			right: 10,
			opacity: 0.5,
			cursor: 'pointer',
			color: '#464ae6',
			fontSize: 16,
			transition: theme.transitions.create(['all'], {
				duration: theme.transitions.duration.complex,
			}),
			'&:hover': {
				fontSize: 18,
				opacity: 1,
			},
		},
		buttonExport: {
			color: '#040633',
			border: `solid 1px #040633`,
			'&:hover': {
				color: '#FFF',
				backgroundColor: '#011d29',
				'& svg': {
					color: '#FFF',
				},
			},
			[theme.breakpoints.down('xs')]: {
				width: '100%',
				marginTop: 16,
			},
			'& svg': {
				margin: 0,
			},
		},
		iconButton: {
			minWidth: 0,
		},
		tableHeader: {
			textAlign: 'center',
			fontWeight: 700,
			fontSize: 18,
		},
		tableDateRow: {
			fontWeight: 700,
			height: 30,
			display: 'flex',
			alignItems: 'center',
			borderRadius: 10,
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.getContrastText(theme.palette.primary.main),
			paddingLeft: 20,
		},
		tableRow: {
			textAlign: 'center',
			fontWeight: 700,
			height: 30,
			display: 'flex',
			alignItems: 'center',
			borderRadius: 20,
			cursor: 'pointer',
			'&:hover': {
				backgroundColor: `${theme.palette.primary.main}2b`,
			},
		},
		lancamentosPaginado: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-end',
			width: '100%',
		},
		negativo: {
			color: `#ff5440 !important`,
		},
		empty: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			fontSize: 18,
			fontWeight: 700,
			height: 200,
		},
		truncate: {
			textOverflow: 'ellipsis',
			overflow: 'hidden',
			whiteSpace: 'nowrap',
		},
		hideOnXs: {
			[theme.breakpoints.down('xs')]: {
				display: 'none',
			},
		},
		controlSwitchDetalhado: {
			marginLeft: 30,
		},
		switchDetalhado: {
			color: theme.palette.primary.main,
		},
		dataHoraXs: {
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
		},
		valorOnXs: {
			[theme.breakpoints.down('xs')]: {
				fontSize: 10,
			},
		},
	};
});

const getOptions = () => {
	const result = {
		tipoTransacao: [
			{
				id: 'Venda',
				name: 'Venda',
			},
			{
				id: 'Split',
				name: 'Split',
			},
			{
				id: 'in',
				name: 'Transferência de entrada',
			},
			{
				id: 'out',
				name: 'Transferência de saida',
			},
			{
				id: 'Estornado',
				name: 'Estornado',
			},
			{
				id: 'Split Regra',
				name: 'Split Regra',
			},
			{
				id: 'Split Taxa',
				name: 'Split Taxa',
			},
		],
	};

	return result.tipoTransacao;
};

const getOptionMethod = () => {
	const result = {
		method: [
			{
				id: 'credit',
				name: 'Cartão de Crédito',
			},
			{
				id: 'debit',
				name: 'Cartão de Débito',
			},
			{
				id: 'pix',
				name: 'Pix',
			},
			{
				id: 'split',
				name: 'Split',
			},
			{
				id: 'bankSlip',
				name: 'Boleto bancário',
			},
			{
				id: 'comission',
				name: 'Comissão',
			},
			{
				id: 'p2pTransfer',
				name: 'Transferência entre Contas',
			},
			{
				id: 'bankTransfer',
				name: 'Transferência Bancária',
			},
			{
				id: 'automatic',
				name: 'Transferência Automatica',
			},
		],
	};

	return result.method;
};
export default props => {
	// const { history, location } = useReactRouter();
	// const { location } = useReactRouter();
	const history = useHistory();
	const location = history.location;
	const { pathname } = location;
	const pathnameSlug = pathname.toLowerCase().replace(/[^a-z0-9]/gi, '');

	const filtersCache = useSelector(store => store.filters[pathnameSlug] || {});

	const query = queryString.parse(location.search);
	const queryFilter = {
		date: { start: addDays(new Date(), -7), end: new Date() },
		tipoTransacao: '',
		method: '',
		...filtersCache,
	};

	if (Isset(() => query.tt)) {
		switch (+query.tt) {
			case 1:
				queryFilter.tipoTransacao = 'Venda';
				queryFilter.method = 'credit';
				break;
			case 2:
				queryFilter.tipoTransacao = 'Split';
				queryFilter.method = 'debit';
				break;
			case 3:
				queryFilter.tipoTransacao = 'in';
				queryFilter.method = 'pix';
				break;
			case 4:
				queryFilter.tipoTransacao = 'out';
				queryFilter.method = 'split';
				break;
			case 5:
				queryFilter.tipoTransacao = 'Estornado';
				queryFilter.method = 'bankSlip';
				break;
			case 6:
				queryFilter.tipoTransacao = 'Split Regra';
				queryFilter.method = 'comission';
				break;
			case 7:
				queryFilter.tipoTransacao = 'Split Taxa';
				queryFilter.method = 'voucher';
				break;
			case 8:
				queryFilter.method = 'p2pTransfer';
				break;
			case 9:
				queryFilter.method = 'bankTransfer';
				break;
			default:
		}
	}

	if (Isset(() => query.d)) {
		switch (query.d) {
			case 'lastMonth':
				queryFilter.date = { default: query.d, start: addMonths(new Date(), -1), end: new Date() };
				break;
			default:
		}
	}

	const classes = useStyles();

	const dispatch = useDispatch();

	const token = useSelector(state => state.auth.token);
	const usuario = useSelector(state => state.auth.usuario);
	// const isAdmin = usuario.grupo_id === 1;
	// const isForPay = usuario.marketplace.id === 2;

	const { id: estabelecimentoId } = useSelector(state => state.admin.estabelecimento) || {};

	const [lancamentos, setLancamentos] = useState([]);

	//paginação
	const [totalRows, setTotalRows] = useState(0);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(200);

	const onChangePage = newPage => {
		setPage(newPage);
	};

	const onChangeRowsPerPage = rowsPerPage => {
		setRowsPerPage(rowsPerPage);
		setPage(1);
	};

	const [filtros, setFiltros] = useState(queryFilter);
	const [detalhado, setDetalhado] = useState(Isset(() => filtersCache.detalhado) ? filtersCache.detalhado : false);
	const [loading, setLoading] = useState(true);
	const estabelecimento = useSelector(state => state.auth.estabelecimento);
	const [showLastExport, setShowLastExport] = useState(false);
	// const [showExportMessage, setShowExportMessage] = useState(false);

	//bloqueio elo7
	if (
		estabelecimento.marketplace.id === 129 &&
		usuario.grupo_id !== 1 &&
		usuario.grupo_id !== 11 &&
		usuario.grupo_id !== 13 &&
		usuario.grupo_id !== 14
	) {
		if (
			usuario.estabelecimento.id !== 46457 &&
			usuario.estabelecimento.id !== 63434 &&
			usuario.estabelecimento.id !== 47207
		) {
			history.push('/');
		}
	}

	const TipoPagamento = type => {
		switch (type) {
			case 'out':
				return 'Transferência de saida';
			case 'in':
				return 'Transferência de entrada';
			default:
				return type;
		}
	};

	useEffect(() => {
		const getExtrato = async () => {
			setLoading(true);
			const extrato = await axios.get(`${config.get('apiV2Url')}bank-statement/list`, {
				headers: { Authorization: `Bearer ${token}` },
				params: {
					page,
					startDate: filtros.date.start,
					endDate: filtros.date.end,
					estabelecimentoId,
					tipoTransacao: filtros.tipoTransacao,
					detalhado,
					method: filtros.method,
					limit: rowsPerPage,
					// filtros: {
					//  ...filtros,
					//  estabelecimentoId,
					//  detalhado,
					// },
				},
			});
			if (extrato.data.success) {
				if (extrato.data.bankStatement.length === 0) {
					setLancamentos([]);
				}
				// const result = [];
				// let lastDate = null;
				// extrato.data.extrato.forEach(v => {
				// 	const date = format(v.data, 'YYYY-MM-DD');
				// 	if (lastDate !== date) {
				// 		lastDate = date;
				// 		result.push({ type: 'date', date });
				// 	}
				// 	v.type = 'row';
				// 	result.push(v);
				// });

				// if (result.length > 0) {
				// 	const lastResult = result[result.length - 1];
				// 	let lastSaldoId = Object.keys(extrato.data.saldo)
				// 		.filter(i => format(i, 'YYYYMMDD') < format(lastResult.data, 'YYYYMMDD'))
				// 		.pop();
				// 	let lastSaldo = extrato.data.saldo[lastSaldoId];
				// 	result.reverse().forEach(v => {
				// 		if (v.type === 'date') {
				// 			const date = format(v.date, 'YYYY-MM-DD');
				// 			const saldo = Isset(() => extrato.data.saldo[date]) ? extrato.data.saldo[date] : lastSaldo;
				// 			v.saldo = saldo;
				// 			lastSaldo = saldo;
				// 		}
				// 	});
				// }
				let date = [];
				let currentDate;
				const lancamentosTraduzidos = extrato.data.bankStatement.map(lancamento => {
					if (currentDate !== moment(lancamento.occurredAt).format('YYYY-MM-DD')) {
						currentDate = moment(lancamento.occurredAt).format('YYYY-MM-DD');
						date.push({
							occurredAt: moment(lancamento.occurredAt).format('YYYY-MM-DD'),
						});
					}

					return {
						...lancamento,
						type: TipoPagamento(lancamento.type),
					};
				});
				console.log(date);

				const saldoPromises = date.map(async d => {
					try {
						if (d.occurredAt !== 'Invalid date') {
							const saldo = await axios.get(`${config.get('apiV2Url')}bank-statement/daily-balance`, {
								headers: { Authorization: `Bearer ${token}` },
								params: {
									rangeDate: {
										gte: moment(d.occurredAt).format('YYYY-MM-DD 00:00:00'),
										lte: moment(d.occurredAt).format('YYYY-MM-DD 23:59:59'),
									},
									estabelecimentoId,
								},
							});
							if (saldo.data.success) {
								const currentBalance = saldo.data.bankStatement.find(s => s.date === d.occurredAt);
								return {
									...d,
									saldo: currentBalance ? currentBalance.currentBalance : 0,
									type: 'date',
								};
							}
						}
					} catch (err) {
						console.error(`Erro ao obter saldo para a data ${d.date}: ${err.message}`);
						return null;
					}
				});
				Promise.all(saldoPromises)
					.then(results => {
						// results.map(result => lancamentosTraduzidos.push(result));
						const result = [];
						let lastDate = null;
						lancamentosTraduzidos.forEach(v => {
							const date = moment(v.occurredAt).format('YYYY-MM-DD');
							if (lastDate !== date) {
								lastDate = date;
								const saldo = results.find(result => result.occurredAt === date);
								result.push({ typeRow: 'date', date, saldoNovo: saldo });
							}
							v.typeRow = 'row';
							result.push(v);
						});

						if (result.length > 0) {
							const lastResult = result[result.length - 1];
							let lastSaldoId = Object.keys(results)
								.filter(
									i => moment(i).format('YYYYMMDD') < moment(lastResult.occurredAt).format('YYYYMMDD')
								)
								.pop();
							let lastSaldo = results[lastSaldoId];
							result.reverse().forEach(v => {
								if (v.typeRow === 'date') {
									const date = moment(v.occurredAt).format('YYYY-MM-DD');
									const saldo = Isset(() => results[date]) ? results[date] : lastSaldo;
									v.saldo = saldo;
									lastSaldo = saldo;
								}
							});
							setLancamentos(result.reverse());
						}
						lancamentosTraduzidos.sort((a, b) => {
							const dateA = new Date(a.occurredAt);
							const dateB = new Date(b.occurredAt);

							// Se ambas as datas forem iguais e o tipo de 'a' for 'date', 'a' vem primeiro
							// if (dateA.getTime() === dateB.getTime() && a.type === 'date') {
							// 	return -1;
							// }
							// Se ambas as datas forem iguais e o tipo de 'b' for 'date', 'b' vem primeiro
							if (dateA.getTime() === dateB.getTime() && b.type === 'date') {
								return 1;
							} else if (dateA.getTime() > dateB.getTime) {
								return -1;
							} else if (dateA.getTime() < dateB.getTime) {
								return 1;
							}
							// Caso contrário, ordena com base nas datas, mas de forma inversa para trazer as mais recentes primeiro
							else {
								return dateB - dateA;
							}
						});
						// setLancamentos(lancamentosTraduzidos);
						setTotalRows(extrato.data.pagination.totalRows);
						setLoading(false);
					})
					.catch(error => {
						console.log(error);
						console.error(`Erro ao processar solicitações de saldo: ${error.message}`);
					});
			} else {
				setLancamentos([]);
				setLoading(false);
			}
			// setLoading(false);
		};

		getExtrato();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtros, page, rowsPerPage, estabelecimentoId, detalhado]);

	const filtrosChange = data => {
		setPage(1);
		setFiltros({
			...filtros,
			...data,
		});
		dispatch(
			setFilters(pathnameSlug, {
				...filtros,
				...data,
				detalhado,
			})
		);
	};
	const exportXlsClick = async () => {
		// if (totalRows > 20000) {
		setShowLastExport(true);
		// } else {
		//  await exportXls();
		// }
	};
	const exportXls = async getLastExports => {
		try {
			await axios.get(`${config.get('apiUrl')}export/extrato`, {
				headers: { Authorization: `Bearer ${token}` },
				responseType: 'blob',
				params: {
					filtros: {
						...filtros,
						estabelecimentoId,
						detalhado,
					},
				},
			});
			getLastExports();

			dispatch(addMessage({ type: 'warning', message: 'Sua nova exportação está sendo processada.' }));

			// const { 'content-disposition': contentDisposition, 'content-type': contentType } = extrato.headers;
			// let filename = 'extrato.xlsx';
			// if (contentDisposition.indexOf('filename="') !== -1) {
			//  filename = contentDisposition.slice(
			//      contentDisposition.indexOf('filename="') + 10,
			//      contentDisposition.length - 1
			//  );
			// }

			// FileDownload(extrato.data, filename, contentType);
		} catch (error) {
			// console.log(error.response.data.text());
			const texto = await error.response.data.text();
			// console.log(texto);
			dispatch(addMessage({ type: 'error', message: texto }));
		}
	};

	const changeDetalhado = e => {
		const value = e.target.checked;
		setPage(1);
		setDetalhado(value);
		dispatch(setFilters(pathnameSlug, { ...filtersCache, detalhado: value }));
	};
	const newExport = async getLastExports => {
		await exportXls(getLastExports);
	};

	return (
		<div className={classes.extrato}>
			<div className={classes.header}>
				Extrato
				<FormControlLabel
					className={classes.controlSwitchDetalhado}
					control={
						<Switch
							className={classes.switchDetalhado}
							checked={detalhado}
							onChange={changeDetalhado}
							value="detalhado"
							color="primary"
						/>
					}
					label="Detalhado"
				/>
			</div>
			<Paper>
				<Grid container className={classes.filtros}>
					<Grid item md={11}>
						<ZFiltros
							onChange={filtrosChange}
							modal={false}
							dateRange={{
								default: 'custom',
								startDate: filtros.date.start,
								endDate: filtros.date.end,
							}}
							selectField={[
								{
									key: 'tipoTransacao',
									defaultValue: filtros.tipoTransacao,
									placeholder: 'Tipo de Transação',
									multiple: false,
									options: getOptions(),
									modal: false,
									md: 3,
								},
							]}
							selectMethodExtrato={[
								{
									key: 'method',
									defaultValue: filtros.method,
									placeholder: 'Metodo de pagamento',
									multiple: false,
									options: getOptionMethod(),
									modal: false,
									md: 3,
								},
							]}
						/>
					</Grid>
					<Grid container item md={1}>
						{usuario.grupo_id !== 7 && (
							<Grid item align="end" md={11} xs={12}>
								<Tooltip title="Exportar">
									<Button
										className={clsx(classes.buttonExport, classes.iconButton)}
										onClick={exportXlsClick}
									>
										<FaDownload className={classes.exportDownload} />
									</Button>
								</Tooltip>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Paper>
			{/*<Paper style={{ padding: 15 }}>*/}
			{/* <div className={classes.filtros}>
                    <ZFiltros
                        onChange={filtrosChange}
                        modal={false}
                        dateRange={{
                            default: 'custom',
                            startDate: filtros.date.start,
                            endDate: filtros.date.end,
                        }}
                        selectField={[
                            {
                                key: 'tipoTransacao',
                                defaultValue: filtros.tipoTransacao,
                                placeholder: 'Tipo de Transação',
                                multiple: true,
                                options: getOptions(),
                                modal: false,
                                md: 3,
                            },
                        ]}
                    />
                    {usuario.grupo_id !== 7 && <FaFileExcel className={classes.export} onClick={exportXls} />}
                </div> */}
			<Dialog open={false}>
				<DialogContent style={{ width: 200 }}>
					<ZLoader height={100} size={26} message={'Exportando'} />
				</DialogContent>
			</Dialog>
			{showLastExport && (
				<LastExport
					show={showLastExport}
					onClose={() => setShowLastExport(false)}
					url={detalhado ? '/export/extrato-detalhado' : '/export/extrato'}
					newExport={newExport}
					loadingProp={loading}
				/>
			)}
			<div className={classes.grid}>
				{loading && lancamentos ? (
					<ZLoader height={200} message="Carregando Extrato" />
				) : (
					<>
						{lancamentos.length > 0 ? (
							<Grid container className={classes.lancamentos}>
								{!detalhado ? (
									<SemDetalhe lancamentos={lancamentos} filtros={filtros} />
								) : (
									<>
										<div className={classes.lancamentosPaginado}>
											<Detalhado lancamentos={lancamentos} filtros={filtros} />

											<ZPagination
												count={totalRows}
												rowsPerPage={rowsPerPage}
												page={page - 1}
												onChangePage={onChangePage}
												onChangeRowsPerPage={onChangeRowsPerPage}
												rowsPerPageOptions={[50, 100, 150, 200]}
											/>
										</div>
									</>
								)}
							</Grid>
						) : (
							//  <Grid container item className={classes.tableHeader}>
							//      {detalhado && (
							//          <Grid item xs={1}>
							//              Hora
							//          </Grid>
							//      )}
							//      <Grid item xs={1} sm={1}>
							//          Tipo
							//      </Grid>
							//      <Grid item xs={2} sm={1}>
							//          Método
							//      </Grid>
							//      {detalhado && (
							//          <Grid item xs>
							//              Cliente
							//          </Grid>
							//      )}
							//      {isAdmin && (
							//          <Grid item xs>
							//              Estabelecimento
							//          </Grid>
							//      )}
							//      <Grid item xs={2}>
							//          Valor
							//      </Grid>
							//      <Grid item xs={1} className={classes.hideOnXs}>
							//          Taxa
							//      </Grid>
							//      <Grid item xs={1} className={classes.hideOnXs}>
							//          Valor Líquido
							//      </Grid>
							//      {detalhado && !isForPay && (
							//          <Grid item xs={2}>
							//              Saldo
							//          </Grid>
							//      )}
							//  </Grid>
							//  {lancamentos.map((lancamento, key) => (
							//      <React.Fragment key={key}>
							//          {lancamento.type === 'date' ? (
							//              <Grid container item className={classes.tableDateRow}>
							//                  <Grid item xs={8}>
							//                      {format(lancamento.date, 'DD [de] MMMM [de] YYYY', {
							//                          locale: pt,
							//                      })}
							//                  </Grid>
							//                  <Grid item xs style={{ textAlign: 'right', paddingRight: 20 }}>
							//                      {!isForPay && Formatter.Real(lancamento.saldo)}
							//                  </Grid>
							//              </Grid>
							//          ) : (
							//              <Grid
							//                  container
							//                  item
							//                  className={classes.tableRow}
							//                  onClick={() => {
							//                      if (!detalhado) {
							//                          return;
							//                      }
							//                      history.push(
							//                          [2, 3].includes(lancamento.tipoId)
							//                              ? '/transferencias/detalhes/' + lancamento.id
							//                              : '/vendas/detalhes/' + lancamento.pedido_id
							//                      );
							//                  }}
							//              >
							//                  {detalhado && (
							//                      <Grid item xs={1} className={classes.dataHoraXs}>
							//                          {format(lancamento.data, 'DD/MM HH:mm')}
							//                      </Grid>
							//                  )}
							//                  <Grid item xs={2} sm={1} className={classes.truncate}>
							//                      {lancamento.tipo}
							//                  </Grid>
							//                  <Grid item xs={2} sm={1} className={classes.truncate}>
							//                      {lancamento.metodo}
							//                  </Grid>
							//                  {detalhado && (
							//                      <Grid item xs className={classes.truncate}>
							//                          {Isset(() => lancamento.cliente.nome)
							//                              ? lancamento.cliente.nome
							//                              : ''}
							//                      </Grid>
							//                  )}
							//                  {isAdmin && (
							//                      <Grid item xs style={{ overflow: 'hidden', maxHeight: 25 }}>
							//                          {lancamento.estabelecimento}
							//                      </Grid>
							//                  )}
							//                  <Grid
							//                      item
							//                      xs={2}
							//                      className={clsx(classes.valorOnXs, {
							//                          [classes.negativo]: lancamento.tipoId === 2,
							//                      })}
							//                  >
							//                      {Formatter.Real(lancamento.valor)}
							//                  </Grid>
							//                  <Grid item xs={1} className={classes.hideOnXs}>
							//                      {Formatter.Real(lancamento.taxa)}
							//                  </Grid>
							//                  <Grid
							//                      item
							//                      xs={1}
							//                      className={clsx(classes.hideOnXs, {
							//                          [classes.negativo]: lancamento.tipoId === 2,
							//                      })}
							//                  >
							//                      {Formatter.Real(lancamento.valor_liquido)}
							//                  </Grid>
							//                  {detalhado && !isForPay && (
							//                      <Grid item xs={2} className={classes.valorOnXs}>
							//                          {Formatter.Real(
							//                              Isset(() => lancamento.saldo) ? lancamento.saldo : 0
							//                          )}
							//                      </Grid>
							//                  )}
							//              </Grid>
							//          )}
							//      </React.Fragment>
							//  ))}
							<div className={classes.empty}>Nenhum registro foi encontrado.</div>
						)}
					</>
				)}
			</div>
			{/*</Paper>*/}
		</div>
	);
};
